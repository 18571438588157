import React from "react";
import { Box, Grid, Typography, Link, Divider } from "@mui/material";
import Setting from "../../../assets/images/footerimg.png";
import { Link as RouterLink } from "react-router-dom";

// Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  // Scroll to section function
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: "20px 16px", sm: "40px 24px", md: "40px 0px" },
        backgroundColor: "#FFFFFF",
        color: "#122638",
        marginTop: "auto",
        zIndex: 1,
        color: "#122638",
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* Footer Image */}
        <Grid
          item
          xs={12}
          sm={4}
          md={2}
          sx={{
            display: "flex",

            mb: { xs: 2, sm: 0 },
            justifyContent: { xs: "center", sm: "center", md: "flex-start" },
          }}
        >
          <img
            src={Setting}
            alt="Footer Setting"
            style={{ width: "100%", maxWidth: "150px" }}
          />
        </Grid>

        {/* Center Content */}
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "24px" }}
          >
            INVAYAS
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "16px", sm: "18px", md: "22px" } }}
          >
            Bangalore | Dubai | France
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#8C2970",
              fontSize: { xs: "16px", sm: "18px", md: "22px" },
            }}
          >
            +91 7892287093
          </Typography>
        </Grid>

        {/* Links */}
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          <Typography variant="h6" gutterBottom>
            <Link
              href="#home"
              onClick={() => scrollToSection("home")}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              Home
            </Link>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <Link
              href="#about-us"
              onClick={() => scrollToSection("about-us")}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              About Us
            </Link>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <Link
              href="#services"
              onClick={() => scrollToSection("services")}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              Services
            </Link>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <Link
              href="#why-choose-us"
              onClick={() => scrollToSection("why-choose-us")}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              Why Choose Us?
            </Link>
          </Typography>
          <Typography variant="h6">
            <Link
              href="#our-team"
              onClick={() => scrollToSection("our-team")}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "bold" }}
            >
              Our Team
            </Link>
          </Typography>
        </Grid>
      </Grid>

      {/* Footer Bottom Section */}
      <Divider sx={{ my: 3 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "20px", sm: "30px" },
          textAlign: { xs: "center", sm: "left" },
          justifyContent: {
            xs: "center",
            sm: "space-between",
            md: "space-around",
          },
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: { xs: "14px", sm: "16px" } }}
        >
          Copyright 2024 @Invayas
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", sm: "20px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <RouterLink
            to="/terms-conditions"
            style={{
              textDecoration: "none",
              color: "inherit",
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            Terms & Conditions
          </RouterLink>
          <RouterLink
            to="/privacy-policy"
            style={{
              textDecoration: "none",
              color: "inherit",
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            Privacy Policy
          </RouterLink>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: "10px", sm: "20px" },
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          <Link
            href="https://www.instagram.com/invayas?igsh=MW0ycnJvNGZmNW9reg=="
            target="_blank"
            color="inherit"
          >
            <InstagramIcon />
          </Link>

          <Link
            href="https://www.linkedin.com/company/invayasindia/"
            target="_blank"
            color="inherit"
          >
            <LinkedInIcon />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
