import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import KnowMore from "../../assets/images/knowmore.png";
import OurVision from "../../assets/images/ourvision.png";
import { styled } from "@mui/material/styles";

// Styled Container for the AboutUs section
const StyledContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh", // Full viewport height
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#f9f9f9",
  padding: theme.spacing(2),
  
  // Apply padding for extra-small screens (xs)
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(4), // Smaller padding for xs screens
  },

  // Apply padding for medium screens and up (md)
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8), // Larger padding for md and up screens
  },
}));

// Styled Image component with responsive design and lazy loading
const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxWidth: "525px",
  maxHeight: "535px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "300px",
    maxHeight: "300px",
  },
  [theme.breakpoints.down("xs")]: {
    maxWidth: "300px",
    maxHeight: "300px",
  },
}));

// Styled Typography for Body Text with alignment and responsiveness
const StyledBodyAboutTypography = styled(Typography)(({ theme }) => ({
  maxWidth: "635px",
  marginTop: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "34px",
  textAlign: "justify",
  color: "#636A87",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "justify",
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "justify",
    marginTop: theme.spacing(2),
  },
}));

// Styled Typography for Heading with consistency in alignment
const HeadingAboutTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans, sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  marginBottom: "20px",
  color: "#122638",
  textAlign: "left",
  fontSize: "28px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "48px",
  },
}));

const AboutUs = () => {
  return (
    <StyledContainer>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} container alignItems="center">
          <Box>
            <HeadingAboutTypography variant="h4" gutterBottom>
              Know more about us
            </HeadingAboutTypography>
            <StyledBodyAboutTypography variant="body1">
              At Invayas we help the business to build an intelligently designed
              digital future. Tell us about your idea, and we’ll offer the most
              fitting technological solution. We are passionate about our
              customers and consumers and embrace their priorities as our own.
              We specialize in outsourcing software development projects and in
              delivering end-to-end development and testing services. Our
              services are specially designed and structured as per our client’s
              needs & cater to various sizes of businesses from startups to
              large-scale businesses.
            </StyledBodyAboutTypography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center">
          <StyledImage src={KnowMore} alt="Know more about us" loading="lazy" />
        </Grid>

        {/* Our Vision and Values Section */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          order={{ xs: 2, md: 1 }}
        >
          <StyledImage
            src={OurVision}
            alt="Our vision and values"
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          order={{ xs: 1, md: 2 }}
        >
          <Box>
            <HeadingAboutTypography variant="h4" gutterBottom>
              Our vision and values
            </HeadingAboutTypography>
            <StyledBodyAboutTypography variant="body1">
              We deliver Software Solutions that contribute Sustainability to
              our Customers' success. Our honesty is our main strength, We are
              always honest & transparent with our clients and we want to carry
              the same in the future. Integrity, adeptness, diligence, hard
              work, agility & dedication are some of the qualities that we
              promote. Our purpose is to develop and promote advanced
              information technologies for multi-user operations. We are
              committed to improving the lives of our clients by providing
              quality services and solutions that earn their trust and build
              lifetime relationships.
            </StyledBodyAboutTypography>
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AboutUs;
