import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Tagskills from "../../../assets/images/tagskills.png";
import Airexpe from "../../../assets/images/airexpe.png";
import AgasincTechnology from "../../../assets/images/agasinctechnologies.png";
import CoralFrames from "../../../assets/images/coralframe.png";
import Aldecor from "../../../assets/images/aldecorindia.png";
import Asdalogo from "../../../assets/images/asdalogow 1.png";
import crossStorage from "../../../assets/images/crossstorage.png";
import CurioStuff from "../../../assets/images/curiostuff.png";

//client images with their respective URLs
const clientData = [
  { src: Tagskills, url: "https://www.tagskills.com" },
  { src: Airexpe, url: "https://www.airexpe.com/" },
  { src: AgasincTechnology, url: "https://agasinc.com/" },
  { src: CoralFrames, url: "https://www.coralframe.com/" },
  { src: Aldecor, url: "https://aldecorindia.com/" },
  { src: Asdalogo, url: "https://asdainternational.com/" },
  { src: crossStorage, url: "https://agasinc.com/CrossStorage" },
  { src: CurioStuff, url: "https://www.curiostuff.com" },
];

// Styled component for the client section
const ClientSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  background: "linear-gradient(245.62deg, #8C2970 0.01%, #122638 34.95%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  padding: theme.spacing(8),
  boxSizing: "border-box",
}));

// Styled component for client images
const ClientImage = styled("img")({
  width: "209px",
  height: "auto",
  objectFit: "contain",
  margin: "0 10px",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
});

const Clients = () => {
  return (
    <ClientSection>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {clientData.map(({ src, url }, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            key={index}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <a href={url} target="_blank" rel="noopener noreferrer">
              <ClientImage src={src} alt={`Client ${index}`} />
            </a>
          </Grid>
        ))}
      </Grid>
    </ClientSection>
  );
};

export default Clients;
