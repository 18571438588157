// src/components/Startup/Startup.js
import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import StartupImage from "../../../assets/images/startup.png";
import ButtonWithIcon from "../../common/Button/ ButtonWithIcon";
import Popup from "../../common/popup/Popup";

// Styled components
const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${StartupImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "325px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  filter: "brightness(0.7)",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const Overlay = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  margin: "8vh",
  color: "#fff",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Josefin Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "48px",
  lineHeight: "75px",
  marginBottom: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "36px",
    lineHeight: "50px",
  },
}));

const Startup = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  return (
    <BackgroundBox>
      <Overlay />
      <ContentBox>
        <Grid container alignItems="center">
          <Grid item xs={12} md={8}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <Title>Got an Exciting Project Idea?</Title>
              <Title>Let's have a conversation about it</Title>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="center" mt={6}>
              <ButtonWithIcon
                text="Start"
                subtext="Project"
                iconColor="#122638"
                bgColor="#DEFC03"
                onClick={handleOpenPopup}
              />
            </Box>
          </Grid>
        </Grid>
        <Popup open={popupOpen} onClose={handleClosePopup} />
      </ContentBox>
    </BackgroundBox>
  );
};

export default Startup;
